@import "assets/styles/base/_variables";
@import 'assets/styles/plugins/spinner-linear';
@import 'assets/styles/plugins/spinner-circular';

/* circular */
.loader-global {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 99;

	> .spinner {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		position: absolute;
		margin: 0;

		> div {
			background-color: #fff;
		}
	}
}

/* linear */
.loader-service-check,
.loader-form-vehicle,
.loader-button-funnel {
	margin: 0;
	display: inline;
	position: absolute;
	height: 20px;
	width: 20px;
}

.loader-button-funnel {
	height: 20px;
	width: 20px;
	right: 25px;

	.sk-circle:before {
		background-color: #fff;
	}

	&.loader-button-center{
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		.sk-circle:before {
			background-color: #000;
		}
	}
}

.loader-loading {
    height: 20px;
    width: 20px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin-right: 10px;

    .sk-circle:before {
        background-color: $brand-gray-dark;
    }

    &.loader-button-center{
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        .sk-circle:before {
            background-color: #000;
        }
    }
}

.loader-service-check {
	top: 30px;
	left: 0;
	background: #f8f9fa;

	@media (min-width: $screen-md-min) {
		top: 40px;
	}
}

/* linear */
.loader-form-vehicle {
	top: 12px;
	right: 10px;
}

.btn-primary{
    .loader-button-funnel .sk-circle:before{
        background-color: #FFFFFF;
    }

    &--outline{
        .loader-button-funnel .sk-circle:before{
            background-color: $btn-primary;
        }
    }
}

.loader-spinner {
    font-size: 10px;
    margin: 2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #ffffff;
    background: -moz-linear-gradient(left, #ffffff 50%, transparent 50%);
    background: -webkit-linear-gradient(left, #ffffff 50%, transparent 50%);
    background: -o-linear-gradient(left, #ffffff 50%, transparent 50%);
    background: -ms-linear-gradient(left, #ffffff 50%, transparent 50%);
    background: linear-gradient(to right, #ffffff 50%, transparent 50%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
}
.loader-spinner:before {
    width: 50%;
    height: 50%;
    background: transparent;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}
.loader-spinner:after {
    background: transparent;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loader-spinner.loader-checkbox {
    border: 1px solid #fff;
    border-radius: 50%;
    border-top: 1px solid $brand-primary;
    border-right: 1px solid $brand-primary;
    border-bottom: 1px solid $brand-primary;
    width: 16px;
    height: 16px;
    margin: 0;
    top: -1px;
    left: -1px;
}

.loader-spinner.loader-link-icon {
    border: 1px solid transparent;
    border-radius: 50%;
    border-top: 1px solid $brand-primary;
    border-right: 1px solid $brand-primary;
    border-bottom: 1px solid $brand-primary;
    width: 13px;
    height: 13px;
    margin: 0;
    margin-right: 10px;
    top: 2px;
    left: 0;
    background: transparent;
    display: inline-block;
}

.btn-md {
    .loader-button-funnel {
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        width: 15px;
        height: 15px;
    }
}

.btn-sm {
    .loader-button-funnel {
        display: block;
        margin: auto;
        position: relative;
        left: 0;
        height: 10px;
        width: 10px;
        transform: scale(2);
    }
}

.loader-request-review {
    width: 100px;
    height: 100px;
    display: block;
    position: relative;
    vertical-align: middle;
    margin: 100px auto;

    .sk-circle:before {
        background-color: $brand-primary;
    }
}
