@import "assets/styles/base/_variables";
@import "assets/styles/base/_mixins";

.dd-container {
	margin: $form-margin;
	position: relative;
	outline: 0;
	width: 100%;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	.dd-filter-input {
		height: auto;
	}
}

.dd-title {
    word-wrap: break-word;
    display: inline-block;
    color: $form-input-label-color;
    font-size: $form-input-label-size;
    margin-left: 0;
    margin-bottom: 6px;

	&--disabled {
        color: $form-input-disabled-color-label;
		cursor: not-allowed;
	}

	&--error {
		color: $brand-danger;
	}

	&--disabled + .dd-header{
		cursor: not-allowed;
	}
}

.dd-header {
	position: relative;

	&__icon {
		cursor: pointer;
		position: absolute;
		right: 15px;
		transform: translateY(-50%);
		top: 50%;
		font-size: $font-size-sm;
        color: $brand-gray-dark;

        &--opened {
            color: $text-color;
        }
	}
}

.dd-select {
	position: relative;
	font-size: 14px;
	display: block;
	width: 100%;
	outline: 0;
	cursor: pointer;
	box-sizing: border-box;
	border-radius: $border-radius;
	border: $form-input-border;
	background-color: $form-input-background;
	min-height: 40px;
	height: auto;
	padding: 10px 30px 9px 16px;
	color: $brand-gray;
	font-weight: $font-weight-regular;

    > .form-group {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        width: 100%;
    }

    > .form-group input {
        color: #606060;
        font-weight: 400;
        margin: 0;
        border: 0;
        min-height: 38px;

        &:focus, &:hover, &:active {
            border: 0 !important;
        }
    }

    > .form-group .input-icon {
        height: 38px;
    }

    &--selected {
		color: $text-color;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .dd-select-trigger-filter {
            overflow: hidden;
            text-overflow: ellipsis;
        }
	}

	&--disabled {
		background-color: $form-input-disabled-background;
		border: $form-input-disabled-border;
		pointer-events: none;
		color: $form-input-disabled-color;
		cursor: not-allowed;
	}

	&--disabled + .dd-header__icon{
		color: $form-input-disabled-color;
		cursor: not-allowed;
	}

	&--error {
		border: 1px solid $brand-danger;
		background-color: $form-error-background;
	}

	&--error + .dd-header__icon {
		color: $brand-danger;
	}

	&--single-item {
		cursor: default;
	}

	&--transparent {
		background-color: transparent;
		border: none;
	}

	&[readonly] {
		cursor: default;
	}

	&:not(.input-error):focus {
		border: 1px solid $brand-primary;
	}

	&::placeholder {
		color: $form-input-placeholder;
	}

    &:hover{
        border: $form-input-hover-border;

        &:not(.dd-select--selected){
            color: $text-gray;
        }
    }

    &:active, &:focus, &--opened {
        border: $form-input-focus-border;
    }

    &--sm {
        padding: 7px 15px 7px 15px;
        min-height: 30px;
    }
}

.dd-list {
    background-color: #fff;
    box-shadow: $select-box-shadow;
    border-radius: 8px;
    max-height: 250px;
    overflow: auto;
    width: 100%;
    position: absolute;
    z-index: 2;
    top: calc(100% + 5px);

    small {
        font-size: 75%;
    }

    &__options {
        list-style-type: none;
        padding: 0;
        margin: 0;

        &--item {
            cursor: pointer;
            padding: 10px 10px 10px 42px;
            font-size: $font-size-sm;
            color: $text-color;
            border-bottom: 1px solid $brand-gray-light;

            &:last-child {
                border-bottom: none;
            }

            &:hover,
            &.highlighted {
                background-color: $brand-gray-light;
            }

            &.selected {
                font-weight: $font-weight-semi-bold;
            }
        }
    }
}

#make_id .dd-list {
	text-transform: uppercase;
}

.dd-no-result {
    padding: 10px 10px 10px 42px;
    font-size: $font-size-sm;
    color: $text-gray-dark;

    .dd-list__options--item{
        padding-left: 0;
        padding-right: 0;
    }

	> p {
		margin: 0;
	}
}

.dd-sm {
    display: inline-block;
    min-width: 100px;
    margin: 0;

    .dd-select {
        padding: 6px 35px 6px 20px;
        min-height: 30px;

        > .form-group{
            input {
                min-height: 30px;
                padding: 6px 35px 6px 35px;
            }

            .input-icon {
                height: 30px;
            }
        }
    }

    .dd-list {
        .dd-list__options--item {
            padding: 10px 10px 10px 10px;
            text-align: center;
        }
    }

    .loader-form-vehicle {
        top: 7px;
        right: 7px;
    }
}
